
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      // end loading overlay setting

      tableData: <any>[],
      ListSchool: [],
      listMerchant: [{ label: "", value: "" }],
      merchant: "Pilih Merchant",
      merchantId: {},
      nfcId: "",
      accountName: "",
      mappingDateStr: "",
      cardStatus: "",
      merchantName: "",
      activateDate: "",
      filter: false,
      description: "",

      name: "",
      title: "",
      schoolName: "",
      schoolId: "",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      combostatus: [
        {
          name: "AKTIF",
          _id: "ACTIVE",
        },
        {
          name: "BELUM DIGUNAKAN",
          _id: "INACTIVE",
        },
        {
          name: "RUSAK",
          _id: "DAMAGED",
        },
        {
          name: "HILANG",
          _id: "LOST",
        },
      ],

      aksesEdit: false,
      aksesDelete: false,

      role: "",
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    const route = useRoute();

    this.merchantId = route.params.id == "all" ? "" : "" + route.params.id;
    this.merchant = route.params.id == "all" ? "" : "" + route.params.id;
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.getLocalStorage();
    this.getData(this.paging.size, this.paging.page);
    this.getListSchool();
    this.getListKategori();
    this.resume();
    setCurrentPageBreadcrumbs("Detail Merchant", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getLocalStorage() {
      if (
        localStorage.getItem("schoolId") &&
        localStorage.getItem("schoolId") != "null"
      ) {
        this.schoolId = localStorage.getItem("schoolId")!;
        console.log("getisi");
      } else {
        this.schoolId = "";
        console.log("getnull");
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTime(val) {
      if (val) {
        return moment(val).format("DD MMMM YYYY");
      } else {
        return "-";
      }
    },
    resume() {
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_merchant/office/" + this.merchantId
      )
        .then((res) => {
          console.log(res);
          this.merchantName = res.data.nama;
          this.description = res.data.description;
        })
        .catch((e) => {});
    },
    getListSchool() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.ListSchool = data;
        }
      );
    },
    filterClick() {
      this.filter = !this.filter;
    },
    pilihMerchant(e) {
      console.log(e);

      this.merchantId = e == null ? "" : e;
      this.merchantName = e == null ? "" : e;
      this.description = e == null ? "" : e;

      this.listMerchant.forEach((el) => {
        if (e == el.value) {
          this.merchantName = el.label;
        }
      });
      this.resume();
      this.getData(this.paging.size, this.paging.page);
    },
    pilihstatus(event) {
      if (event) {
        this.cardStatus = event;
      } else {
        this.cardStatus = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    getData(size, page) {
      this.isLoading = true;

      let nfcId = "";
      if (this.nfcId) {
        nfcId = "&nfcId=" + this.nfcId;
      } else {
        nfcId;
      }

      let schoolId = "";
      if (this.schoolId) {
        schoolId = "&schoolId=" + this.schoolId;
      } else {
        schoolId;
      }
      let cardStatus = "";
      if (this.cardStatus) {
        cardStatus = "&cardStatus=" + this.cardStatus;
      } else {
        cardStatus;
      }
      let merchantName = "";
      if (this.merchantName) {
        merchantName = "&merchantName=" + this.merchantName;
      } else {
        merchantName;
      }
      let description = "";
      if (this.description) {
        description = "&description=" + this.description;
      } else {
        description;
      }
      console.log(this.merchantName, "aa");
      let accountName = "";
      if (this.accountName) {
        accountName = "&accountName=" + this.accountName;
      } else {
        accountName;
      }
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }

      ApiService.getWithoutSlug(
        "crmv2/main_card/card_stock/" +
          office +
          "stock/all?merchantId=" +
          this.merchantId +
          schoolId +
          nfcId +
          cardStatus +
          schoolId +
          merchantName +
          description +
          accountName +
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=createTime&dir=1"
      )
        .then(({ data }) => {
          this.isLoading = false;

          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          console.log(this.schoolId);
          localStorage.setItem("schoolId", this.schoolId);
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    getListKategori() {
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "all";
      } else {
        office = "office/combo_all";
      }
      ApiService.getWithoutSlug("crmv2/main_card/card_merchant/" + office).then(
        ({ data }) => {
          var as = [
            {
              nama: "",
              description: "",
              _id: "",
            },
          ];
          if (this.role == "ADMIN_SCHOOL") {
            as = data.content;
          } else {
            as = data;
          }
          var cek = [
            {
              label: "Pilih Merchant",
              value: "",
            },
          ];
          as.forEach((element) => {
            // if(element._id == this.merchant){
            //   this.merchantName = element.nama
            // }
            console.log(element.nama);
            var a = element.nama;
            cek.push({
              label: element.nama,
              value: element._id,
            });
          });

          this.listMerchant = cek;
          // localStorage.setItem("listMerchant", JSON.stringify(data));
          // location.reload();
        }
      );
    },
    pilihSekolah(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.schoolId = event;
        localStorage.setItem("schoolId", JSON.stringify(event));
        console.log("isi");
      } else {
        this.schoolId = "";
        localStorage.removeItem("schoolId");
        console.log("null");
      }
      this.getData(this.paging.size, this.paging.page);
    },
    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
  },
});
